<template>
  <div style="height: 94vh;">
    <div id="IpBanner">
      <div  class="fcadescript mb-5">
        <div class="container content-space-2 content-space-lg-3">
          <div class="row">
            <div class="col-lg-12 mb-9 mb-lg-0">
              <h1 class="sol-001">Services<span class="sol-002"> 서비스 결제</span></h1>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <!--      FAQ 탭 추가-->
      <div>

      </div>

      <div class="infomain container content-space-2 content-space-lg-3">
        <div id="board_title" class="row">
          <div id="main_title">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
            </svg> 서비스 이용권 구매
          </div>
          <div id="sub_title">
            유료 서비스 이용을 통해 PA Soft가 제공하는 강력한 AutoClosing 기능을 사용해 보세요.
          </div>
          <div>
<!--            <div class="card price_card row" v-for="(row,index) in orderarray" :key="row.id" >-->
<!--              <div class="card-body">-->
<!--                <div class="b_info">-->
<!--&lt;!&ndash;                <span class="b_item">{{ row.id }}</span>&ndash;&gt;-->
<!--                <span class="b_item1">{{ row.description }}</span>-->
<!--                <span class="b_item2">사용기간: {{ row.additional_month}} 개월</span>-->
<!--                <span class="b_item3">{{ row.additional_day}} 일</span>-->
<!--                <span class="b_item4">가격: {{ row.price }} 원</span>-->
<!--                </div>-->
<!--                <div class="paybtn">-->
<!--                  <button v-on:click="confirm($event, row)" :index="row.id">구매하기</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <div class="row products">
              <div class="col-sm-4 mb-3 mb-sm-0" v-for="(row,index) in orderarray" :key="row.id">
                <div class="card pcard">
                  <div class="card-body pcard_body">
                    <div class="col-sm-12">
                      <h5 class="card-title pro_title">{{ row.product_name }}</h5>
                    </div>
                    <div class="col-sm-12">
                      <p class="card-text pro_des1">{{ row.additional_month}} Month | PRICE: ￦{{ row.price }}</p>
                      <p class="card-text pro_des2">{{ row.description }}</p>
                    </div>
                    <div class="paybtn">
                      <button v-on:click="confirm($event, row)" :index="row.id">구매하기</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <button type="button" style="display: none" id="confirmBill" class="btn btn-primary detail_btn" data-bs-toggle="modal" data-bs-target="#confirm_Bill">삭제</button>
    <!--결제확인 모달폼-->
    <div class="modal fade" id="confirm_Bill" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="height:40px">
            <h1 class="modal-title fs-5" style="font-size: 17px !important;" id="exampleModalLabel">Service Payments</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            선택하신 상품 정보는 다음과 같습니다.<br>
            <span style="line-height: 34px; color: #2152a9; font-size: 13px;">▶ 상품명: {{pName}} |   가격: ￦{{pPrice}}</span><br>
            결제를 계속 진행 하시겠습니까?
          </div>
          <div class="modal-footer" style="eight: 54px; padding: 7px;">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-primary" @click="processBill">구매</button>
          </div>
        </div>
      </div>
    </div>

    <!--     결제 성공  모달폼-->

    <button type="button" class="varify Run_E_Modal" data-bs-toggle="modal" data-bs-target="#Paid_Varidation" style="display:none">결제 모달 실행</button>

    <div class="modal fade" id="Paid_Varidation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="Point_viewlabel" aria-hidden="true">
      <div id="Point_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Payment Result</h1>
            <button type="button" class="btn-close E_modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="padding-top: 0px;">
            <form autocomplete="off">
              <div class="varid_Title">
                <span id="notice"></span><br>
                <p id="paymentResult"></p>
<!--                <p id="retMsg"></p>-->
              </div>
              <div class="input_Valid">
                <div class="col-sm-12" style="align-items: center; display: flex; justify-content: center">
                  <button type="button" class="original col-sm-2" @click="updateUserInfo" style="background-color: #0e2238; margin-right: 10px;">확   인</button>
                </div>
              </div>

            </form>

          </div>


        </div>
      </div>
    </div>




  </div>
</template>

<script>
export default {
  name: 'payModule',
  data() {
    return {
      orderarray:[],
      pkey: null,
      pName: null,
      pPrice: null
    }
  },
  mounted(){
    this.getlist()
  },

  methods: {

    getlist() {
      const t =this
      const url = '/billing/productList'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {}

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {

        const data = res.data.productList.map(item=>{
          const formattedPrice = new Intl.NumberFormat('en-US').format(item.price);
          return {
            ...item,               // 기존 데이터 포함
            price: formattedPrice  // 쉼표 포맷이 적용된 price 값
          };
        })
        t.orderarray = data
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },

    confirm(e,row){
      const t = this
      let b = localStorage.getItem("mobileAuth");
      if (b === "true") {
        t.pkey = row.purchaseKey
        t.pName = row.product_name
        t.pPrice = row.price
        $('#confirmBill').click()
      } else {
        alert("본인 인증이 필요한 기능입니다. [마이페이지]에서 본인인증을 완료해 주세요");
        window.location.href = "/accpage/myinfo";
      }
    },

    closeWindow(){
      const targetWindow = window.open('', 'billing');
      if (targetWindow && !targetWindow.closed) {
        targetWindow.close();
      }
    },

    processBill (){
      //모달 닫기
      $('.btn-close').click()
      // 입력값을 URL에 추가
      const url = '/api/billing/requestOrder?productKey=' + this.pkey;

      // 새 창 열기
      this.childWindow =
          window.open(
          url,
          'billing',
          'width=750, height=550, top=100, left=100, fullscreen=no, menubar=no,' +
          'status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );

      window.addEventListener('message', this.Message.bind(this));

    },

    Message(event) {
      if (event.data.type === 'billingResult') {
        // 메시지 수신 후 창닫고 인증코드 등록하기
        const t =this
        $('.Run_E_Modal').click()

        t.closeWindow()

        //결제결과
        if(event.data.result)
        {
          document.getElementById('paymentResult').textContent = "결제를 완료하였습니다.";
        }
        else{
          document.getElementById('paymentResult').textContent = "결제를 실패하였습니다.";
        }

        // //메세지
        // if(event.data.retMsg)
        // {
        //   document.getElementById('retMsg').textContent = event.data.retMsg;
        // }
      }

    },

    updateUserInfo(){
      $('.E_modalClose').click()
      this.updatePeriod()
    },

    updatePeriod(){
      const t = this
      const url = '/account/info'
      const utoken = $store.state.token != null ? true : false;

      $api.getApiData(url, utoken, successStat, errorStat);

      function successStat(res) {

        const billingPeriod = res.data.data.billingPeriod;
        const formattedBillingPeriod = billingPeriod
            ? billingPeriod.split(' ')[0] // ' ' 기준으로 분리 후 날짜 부분만 가져오기
            : null;
        localStorage.setItem("billingPeriod", formattedBillingPeriod);
        window.location.reload()
      }

      function errorStat(error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }
    }

  },
}
</script>

<style scoped>

.products{
margin-top: 40px;
}

.pcard{
 border-radius:10px;
}

.pro_title{
  font-weight: bold;
  font-size: 20px;
  padding-left: 5px;
  margin-top: -3px !important;
}

.pro_des1{
  margin-bottom:5px!important;
  font-size: 15px;
  padding-left: 5px;
}

.pro_des2{
  color:#c5c5c5;
  font-size: 13px;
  margin-bottom:20px!important;
  padding-left: 5px;
  border-top: #ffffff57 1px solid;
}

.pcard_body{
  background: linear-gradient(to bottom, #212121, #434040);
  color: white;
  border-radius:10px;
}

#main_body{
height:100vh !important;
}

.fcadescript{height:200px;
  background: linear-gradient(to top, rgb(0 0 0 / 10%), rgba(255, 255, 255, 0));

}


#IpBanner {
  background-image: url(@/components/CommonPage/images/companyimg.jpg);
  background-repeat: no-repeat; /* 배경이미지X */
  background-size: cover; /* 요소를 비율에 맞게 커버 */
  background-position: center; /* 이미지를 요소의 정가운데로 처리 */
//background-attachment: fixed; /* 스크롤바 움직일때 이미지가 따라다님 */
}

.sol-001{padding:64px 50px 15px 50px;
  color:white;
  font-size:39px;
  font-weight:700;
  font-family: Pretendard-Regular;
}

.sol-002{color:white;
  padding-left:20px;
  font-family: Pretendard-Regular;
  font-size:25px;}


.infomain{
  padding-left: 13%;
  font-size: 16px;
  line-height: 29px;
}

.price_card{
  margin-top: 20px;
  width:80%;
}
.card-body{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}


.paybtn {

}

.paybtn button {
  width:100px;
  height:31px;
  border: 1px solid #d5d5d5;

}

.paybtn button:hover {
  background-color: #0e2238;
  color:white;

}
body {
  height:100%;
}



.modal {
  --bs-modal-width:460px;
  --bs-modal-border-radius: 4px;
}

.modal-title{
  font-family: 'Pretendard-Regular';
}

.modal-header {
  height: 40px;
}
.modal-header h1 { margin-top: 0px;}
.modal-body h4 {
  font-size: 17px;
  font-weight: bold;
}


.varid_Title{
  margin-bottom: 21px;
  margin-top: 15px;
  font-size: 13px;
}


.original {
  width: 100px;
  height: 25px;
  border: none;
  color: white;
  background-color: #959595;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  float:right;
  cursor:pointer;
}

int_area input {
  witdh:100%;
  background-color: transparent;
}

input::placeholder {
  color: lightgrey;
  font-weight:400;
}

.col-sm-12 {
  padding-top: 10px;
}




</style>